import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // background: #e8fff5;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  color: #262626;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.align ? props.align : "flex-start")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  max-width: 1260px;
  width: 100%;
  margin: 0px auto;
  padding: 20px 20px 30px;
  position: relative;
  flex-wrap: wrap;

  @media (max-width: 550px) {
    flex-direction: column;
    padding: 15px 20px;
  }
`

const ProductBoxWrapper = styled.div`
  display: flex;
  align-items: ${props => (props.align ? props.align : "flex-start")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  width: 100%;
  position: relative;
  flex-wrap: wrap;

  @media (max-width: 550px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    flex-direction: column;
  }
`

const ProductBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 350px;
  max-width: 350px;
  overflow: hidden;
  margin: 20px 25px;
  position: relative;
  border-radius: 50px 0px;
  box-shadow: rgba(206, 206, 206, 0.5) 0px 0px 15px 2px;

  @media (max-width: 550px) {
    min-width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 10px 10px;
  }
`

const ProductTitle = styled.h2`
  font-size: 32px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin: 0px 0px 5px 0px;
`

const ProductSubTitle = styled.p`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  margin: 0px;
`

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white.base};
  color: ${props => props.theme.colors.black.base};
  width: 100%;
  height: 450px;
  max-height: 450px;
  border-radius: 50px 0px;
  overflow: hidden;
  margin: 0px;
  position: relative;
`

const ProductLink = styled(Link)`
  position: absolute;
  top: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const ProductLinkTitle = styled.span`
  display: none;
`

const TopSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #262626;
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.colors.black.base};
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => (padding ? padding : "25px")};
  position: relative;
`

const ProductBottomInfoWrapper = styled.div`
  position: absolute;
  border-radius: 50px 0px;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  height: 250px;
  display: flex;
  flex-direction: column;

  @media (max-width: 400px) {
    height: 270px;
  }
`

const ProductBottomInfo = styled.div`
  position: relative;
  border-radius: 50px 0px;
  width: 100%;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`

const ProductImage = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;

  object-position: ${({ order }) =>
    order === 1 ? "center -40px;" : "center center"};
  object-fit: cover;
  min-height: ${({ order }) => (order === 1 ? "290px" : "250px")};
`

const ProductTopTextWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 7px;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
  text-align: center;
  border-radius: 15px 0px;
`

const TopText = styled.div`
  font-family: "Museo";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  span {
    font-family: "BebasNeueBold";
    font-weight: bold;
    font-size: 48px;
    line-height: 1;
    display: block;
  }
`

const DiscountText = styled.p`
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 36px;
  line-height: 1.1;
  color: #262626;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
  border-radius: 15px 0px;
  padding: 3px 0px;
  margin: 0px;
  max-width: 75px;
  text-align: center;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  margin: 10px 0px 15px;
`

const StyledLink = styled(Link)`
  background: linear-gradient(60.26deg, #4aeba0 16.16%, #19c3de 84.51%);
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.button.black};
  font-size: 18px;
  padding: 12px 20px;
  line-height: 1.2;
  width: 100%;
  position: relative;
  border-radius: 20px 0px;

  :hover {
    color: ${props => props.theme.colors.button.black};
    background: linear-gradient(60.26deg, #4aeba0 16.16%, #19c3de 84.51%);
  }
`

const HomeBottomSection = ({ intl, data }) => {
  return (
    data && (
      <Wrapper>
        <Container>
          <ProductBoxWrapper>
            {data.list.map((item, index) => {
              return (
                item && (
                  <ProductBox to={item.button_url} key={index.toString()}>
                    <ProductCard>
                      {item.button_text && item.button_url && (
                        <ProductLink to={item.button_url}>
                          <ProductLinkTitle>
                            {item.button_text}
                          </ProductLinkTitle>
                        </ProductLink>
                      )}
                      <TopSection>
                        {item.image && (
                          <ProductImage
                            className="lazyload"
                            data-src={item.image}
                            alt={item.alt ? item.alt : ""}
                            order={index}
                          />
                        )}
                        {item.top_text && (
                          <ProductTopTextWrapper>
                            <TopText
                              dangerouslySetInnerHTML={{
                                __html: item.top_text,
                              }}
                            />
                            {item.discount_text && (
                              <DiscountText
                                dangerouslySetInnerHTML={{
                                  __html: item.discount_text,
                                }}
                              />
                            )}
                          </ProductTopTextWrapper>
                        )}
                      </TopSection>
                      <BottomSection padding="0px">
                        <ProductBottomInfoWrapper>
                          <ProductBottomInfo>
                            {item.title && (
                              <ProductTitle
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              />
                            )}
                            {item.text && (
                              <ProductSubTitle
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              />
                            )}
                            {item.button_text && item.button_url && (
                              <FlexWrapper>
                                <StyledLink to={item.button_url}>
                                  {item.button_text}
                                </StyledLink>
                              </FlexWrapper>
                            )}
                          </ProductBottomInfo>
                        </ProductBottomInfoWrapper>
                      </BottomSection>
                    </ProductCard>
                  </ProductBox>
                )
              )
            })}
          </ProductBoxWrapper>
        </Container>
      </Wrapper>
    )
  )
}

export default injectIntl(HomeBottomSection)
